<template>
  <div
    v-if="boughtProduct && userPrice || cartStore.addedToCartCount > 0"
    class="fixed bottom-0 desk:bottom-auto desk:right-32 right-0 z-modalsContent bg-lightest w-full desk:w-[480px]"
    :class="{
      'desk:top-[85px]': isScrolled && scrollPos > 44,
      'desk:top-[161px]': !isScrolled || scrollPos <= 44,
    }"
  >
    <div
      class="shrink-0 grow-0 basis-56
             flex w-full items-center justify-between
             px-16 border-b border-light
             select-none py-16"
    >
      <div class="type-headline-xl leading-single flex items-start">
        {{ $t('addedToCart.headline') }}
      </div>
      <button
        class="
          w-24 h-24 text-right cursor-pointer
          outline outline-4 outline-transparent rounded-6
          hover:outline-brandLight hover:bg-brandLight transition-all"
        @click="uiStore.setShowAddedToCart(false)"
      >
        <img
          src="/icons/close.svg"
          class="h-24 w-24 inline-block"
          alt="Close"
        >
      </button>
    </div>
    <div class="p-16">
      <div v-if="cartStore.addedToCartCount > 1">
        <span class="type-sm desk:type-base">{{ $t('addedToCart.numProductsAdded', {num: cartStore.addedToCartCount}) }}</span>
      </div>
      <div v-else class="flex justify-between">
        <nuxt-link
          :to="productUrl(boughtProduct.url)"
          class="basis-64 h-80 shrink-0 ratio-product block relative flex items-center justify-center"
        >
          <div v-if="boughtProduct.image">
            <nuxt-img
              preset="standard"
              provider="norce"
              loading="lazy"
              sizes="sm:124px"
              :src="boughtProduct.image"
              class="w-48 h-48 object-cover"
            />
            <div class="imageOverlay absolute top-0 left-0 w-full h-full" />
          </div>
          <img
            v-else
            src="/icons/no-image.svg"
            class="aspect-product w-64 rounded-6"
            alt="no image"
          >
          <div v-if="userPrice?.price?.priceBeforeVat === '0'" class="absolute -top-6 -left-6 bg-lightest h-28 w-28 rounded-full flex items-center justify-center">
            <div class="h-24 w-24 rounded-full bg-brandPrimary flex items-center justify-center">
              <img
                class="w-12 h-12"
                src="/icons/gift.svg"
              >
            </div>
          </div>
        </nuxt-link>
        <div
          class="w-full flex flex-col justify-between ml-16"
        >
          <div
            class="flex justify-between items-start"
          >
            <div class="type-xs desk:type-sm mb-8">
              <nuxt-link :to="productUrl(boughtProduct.url)">
                {{ boughtProduct.name }}
              </nuxt-link>
              <div class="type-xs text-dark">
                {{ boughtProduct.partNo }}
              </div>
            </div>
          </div>
          <div
            class="flex items-center"
          >
            <div
              v-if="userPrice.status !== 'pending'"
              class="text-left"
            >
              <div
                class="type-xs-medium desk:type-sm-medium whitespace-nowrap"
                :class="{'text-darkest': userPrice?.price?.isOnSale}"
              >
                {{ useLocalizedCurrency(priceByQuantity.price) }}
              </div>

              <div
                v-if="userPrice?.price?.isOnSale && priceByQuantity.priceBeforeDiscount > 0"
                class="type-xs-medium text-darker line-through whitespace-nowrap"
              >
                {{ useLocalizedCurrency(priceByQuantity.priceBeforeDiscount) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <nuxt-link :to="$manualUrl('/checkout')" class="btn w-full mt-16 group">
        <img
          alt=""
          src="/icons/arrow-right-inv.svg"
          class="inline-block w-16 h-16 mr-12 group-hover:translate-x-4 transition-all duration-200 ease-out"
        >
        {{ $t('minicart.toCheckout') }}
      </nuxt-link>
    </div>
  </div>
  <!--- <img alt="" src="/icons/arrow-right-inv.svg" class="inline-block w-16 h-16 mr-12"> ---->
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui';
import { useCartStore } from '~/store/cart';
import useLinkReplacer from '~/composeables/useLinkReplacer';
import { ProductRaw, ProductVariantOld } from '~/constants/types/algolia';
import { useUserStore } from '~/store/user';
import { useLocalizedCurrency } from '~/composeables/useLocalizedCurrency';
import { CampaignItem } from '~/constants/types/multiDiscount';
import useMultiDiscount from '~/composeables/useMultiDiscount';

const { productUrl } = useLinkReplacer();

const uiStore = useUiStore();
const cartStore = useCartStore();
const { $toNumber } = useNuxtApp();

defineProps<{
  scrollPos: number;
  isScrolled: boolean;
}>();

const boughtProduct = ref<ProductRaw | ProductVariantOld | null>(null);
const userStore = useUserStore();

onMounted(async()=> {
  boughtProduct.value = cartStore.currentlyBuying;
});

onBeforeUnmount(()=> {
  cartStore.setAddedToCartCount(0);
});

const userPrice = computed(() => {
  return userStore.getPrice(cartStore.currentlyBuying?.partNo || '');
});

const { percentageCampaigns } = useMultiDiscount({ activeVariant: cartStore.currentlyBuying });

const priceByQuantity = computed(() => {
  const basePrice = cartStore.currentlyBuyingLockedPriceList
    ? Number(userPrice.value?.price?.priceBeforeDiscountBeforeVat ?? '0')
    : Number(userPrice.value?.price?.priceBeforeVat ?? '0');

  let price = 0;
  if (percentageCampaigns.value.length > 0 && cartStore.currentlyBuyingLockedPriceList) {
    const highestLimitReachedTrue = percentageCampaigns.value
      .filter(item => cartStore.currentlyBuyingQuantity >= item.limit)
      .reduce<CampaignItem | null>((acc, item) => {
        return !acc || item.limit > acc.limit ? item : acc;
      }, null);

    price = highestLimitReachedTrue
      ? cartStore.currentlyBuyingQuantity * basePrice * ((100 - highestLimitReachedTrue.gainedAmount) / 100)
      : cartStore.currentlyBuyingQuantity * basePrice;
  } else {
    price = cartStore.currentlyBuyingQuantity * basePrice;
  }

  return {
    price: price,
    priceBeforeDiscount: $toNumber(userPrice.value?.price?.priceBeforeDiscountBeforeVat || '0') * cartStore.currentlyBuyingQuantity,
  };
});
</script>

<style scoped lang="postcss">
</style>
