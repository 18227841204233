<template>
  <component
    :is="GenericSideMenu"
    position="right"
    class="EditProductDiscountModal"
    :title="$t('checkout.orderProposal.editDiscount')"
    @close="uiStore.closeAllModal()"
  >
    <MiniCartProduct
      v-for="item in items"
      :key="item.partNo"
      :item="item"
      :hide-delete="true"
      :hide-multi-discount="true"
      :hide-cross-docking="!globalContent.getAllowBackorders || userStore.isSalesRepUser || userStore.isCustomerSuccessUser || cartStore.items.length < 2"

    />
    <div class="flex flex-col">
      <p class="type-sm-medium mt-12 mb-6">{{ $t('checkout.orderProposal.editDiscount') }}</p>
      <p class="type-sm">{{ $t('checkout.addDiscountModal.description', { name: uiStore.currentlyEditingItem?.variantName}) }}</p>
    </div>
    <div class="type-sm-medium mb-24 mt-12 flex flex-col gap-8">
      <div class="flex gap-8">
        <div class="basis-full">
          <TextInput
            v-model="selectDiscountQuantity"
            :type="'number'"
            :label="$t('orders.table.quanity')"
          />
        </div>

        <div class="basis-full">
          <TextInput
            v-model="discountPercentage"
            :type="'number'"
            :label="$t('priceInfo.discountLabel') + ' %'"
            :attr="{min: '0', max: '100'}"
          />
        </div>
      </div>

<!--      <div-->
<!--        v-if="!uiStore.currentlyEditingItem?.priceInfo.isStandardPrice || uiStore.currentlyEditingItem.priceInfoTotal.price !== uiStore.currentlyEditingItem.priceInfoTotal.priceBeforeDiscount"-->
<!--        class="flex gap-4 mt-12 basis-full"-->
<!--      >-->
<!--        <div class="flex items-center">-->
<!--          <div class="mr-8">-->
<!--            <input-->
<!--              v-model="priceListLocked"-->
<!--              type="radio"-->
<!--              :value="false"-->
<!--            >-->
<!--          </div>-->
<!--          <div class="type-sm-medium mr-8">-->
<!--            {{ $t('fastOrder.row.user_pricelist') }}-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="px-8 flex items-center">-->
<!--          <div class="mr-8">-->
<!--            <input-->
<!--              v-model="priceListLocked"-->
<!--              type="radio"-->
<!--              :value="true"-->
<!--            >-->
<!--          </div>-->
<!--          <div class="type-sm-medium mr-8">-->
<!--            {{ $t('fastOrder.row.std_pricelist') }}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

    </div>
    <button
      class="btn w-full "
      :class="{ 'loading': loading }"
      :disabled="loading"
      @click="applyDiscount"
    >
      {{ $t('minicart.discount.activate') }}
    </button>

<!--    <button
      :class="{ 'disabled': loading }"
      class="btn w-full btn mb-24"
      @click="uiStore.closeAllModal()"
    >
      {{ $t('search.cancel') }}
    </button>-->
  </component>
</template>

<script setup lang="ts">
import GenericSideMenu from '~/components/menu/GenericSideMenu.vue';
import { useUiStore } from '~/store/ui';
import { useUserStore } from '~/store/user';
import { useCartStore } from '~/store/cart';
import { useGlobalContentStore } from '~/store/globalContent';
import useApiFetch from '~/composeables/useApiFetch';
import MiniCartProduct from '~/components/cart/MiniCartProduct.vue';
import TextInput from '~/components/form-elements/TextInput.vue';

const uiStore = useUiStore();
const userStore = useUserStore();
const cartStore = useCartStore();
const globalContent = useGlobalContentStore();

const { handleApiError } = useApiFetch();
const discountPercentage = ref<number>(0);
const items = ref([]);
const selectDiscountQuantity = ref(1);
const loading = ref(false);
const priceListLocked = ref(false);

onMounted(()=> {
  setItem();
});

const setItem = () => {
  items.value = [uiStore.currentlyEditingItem];
};

const applyDiscount = async() => {
  loading.value = true;
  let res = null;
  const discount = discountPercentage.value;

  if (selectDiscountQuantity.value > 0 && uiStore.currentlyEditingItem) {
    res = await cartStore.updateProductDiscount(
      Number(uiStore.currentlyEditingItem.lineNo),
      discount.toString(),
      selectDiscountQuantity.value,
      true
    );
    if (res) {
      cartStore.cart = res;
      uiStore.currentlyEditingItem = res.items?.filter(item => item.lineNo === uiStore.currentlyEditingItem.lineNo)[0];
      uiStore.closeAllModal();
    } else {
      handleApiError();
    }
  }

  loading.value = false;
};

watch(() => uiStore.currentlyEditingItem, () => {
  setItem();
});
</script>

<style scoped lang="postcss">
</style>
